import React from "react"
import Utils from "../../utils/node.utils"
import { StaticQuery, graphql } from "gatsby"
import IndexPage from "../../components/IndexPage/IndexPage"

const HomeIndexPage = ({ location }) => {
  Utils.NavigateToBrowserLanguage("nl", "/", location.state)
  return (
    <StaticQuery
      query={pageQuery}
      render={data => {
        const commonContentMap = Utils.ContentListToMap(
          data.commonContent.item.content
        )
        const contentMap = Utils.ContentListToMap(
          data.indexContent.item.content
        )
        const language = "nl"
        return (
          <IndexPage
            location={location}
            language={language}
            title={contentMap.seo_title.value}
            description={contentMap.seo_description.value}
            keywords={Utils.GetKeywordsFromTags(data.indexContent.item.tags)}
            contentMap={contentMap}
            commonContentMap={commonContentMap}
            products={data.products.edges}
            categories={data.categories.edges.map(item => item.node.item)}
            carouselItems={Utils.GetCarouselImagesFromContent(
              data.indexContent.item.content,
              "images",
              contentMap.button_shop.value
            )}
          />
        )
      }}
    />
  )
}

export default HomeIndexPage

const pageQuery = graphql`
  {
    indexContent: indexContentNlnl {
      item {
        key
        tags {
          content {
            key
            value
          }
        }
        content {
          key
          value
        }
      }
    }

    commonContent: commonContentNlnl {
      item {
        key
        content {
          key
          value
        }
      }
    }

    categories: allCategoriesEngb(sort: { fields: [item___key], order: ASC }) {
      edges {
        node {
          item {
            id
            key
            content {
              key
              value
            }
            page_children {
              id
              key
              content {
                key
                value
              }
            }
          }
        }
      }
    }

    products: allProductsNlnl(
      sort: { fields: [item___collectionIndex], order: ASC }
      limit: 10
    ) {
      edges {
        node {
          item {
            id
            key
            slug
            content {
              key
              value
            }
          }
        }
      }
    }
  }
`
